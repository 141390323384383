import {io} from "socket.io-client";
import {config} from "./config";
import ESP8266 from "../ESP8266/ESP8266";
import "./styles/styles.scss";

const socket = io(config.wss);

const App = () => {
    return (
        <ESP8266 socket={socket} />
    )
}

export default App;