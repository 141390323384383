import React, {useEffect, useState} from "react";

const ESP8266 = ({socket}) => {
    const [temp, setTemp] = useState('--.--');

    useEffect(() => {
        const handleNewMessage = (message) => {
            console.log(message);
            if(message && message.temp) {
                setTemp(message.temp);
            }
        }

        socket.on('message', handleNewMessage);

        return () => {
            socket.off('message', handleNewMessage);
            socket.disconnect();
        }
    }, [socket]);

    return (
        <div className="esp8266">
            <div className="esp8266__temp">
                {temp}°C
            </div>
        </div>
    );
}

export default ESP8266;
